import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from "gatsby";
import get from "lodash/get";
import { Layout } from "../components/mdxLayout";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Button } from "../components/button";
import SEO from "../components/seo";
import { IconBackground } from "../components/IconBackground";
export const DownloadButton = ({
  url
}) => <a href={url} target="_blank" className="mt-8 inline-block">
    <Button mdxType="Button">Download now</Button>
  </a>;
export const DownloadButtonWhite = ({
  url
}) => <a href={url} target="_blank" title="Schedule a demo" className="border border-gray-300 shadow-sm bg-white text-grape rounded-lg font-medium text-lg p-4 px-6 hover:gray-50">
      Download now
    </a>;
export const GetInTouchCta = () => <div className="text-xl text-purple-light mt-8 bg-violet-light p-4 rounded-xl w-full">
    <strong>Want to learn more? </strong>
    Schedule a quick 15 minute call with our team
    <div className="text-normal mt-4">
      <a href="https://calendly.com/trybe-ricky/15min" target="_blank">
        <Button mdxType="Button">Get in touch</Button>
      </a>
    </div>
  </div>;
export const pageQuery = graphql`
  query WhitepaperBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  ) {
    contentfulWhitepaper(slug: { eq: $slug }) {
      slug
      title
      publishDate(formatString: "MMMM Do, YYYY")
      rawDate: publishDate
      heroImage {
        title
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
      whitepaper {
        file {
          url
        }
      }
      body {
        body
        childMarkdownRemark {
          html
          rawMarkdownBody
          timeToRead
        }
      }
      heroBody {
        heroBody
        childMarkdownRemark {
          html
          rawMarkdownBody
          timeToRead
        }
      }
      description {
        childMarkdownRemark {
          excerpt
        }
      }
    }
    previous: contentfulWhitepaper(slug: { eq: $previousPostSlug }) {
      slug
      title
    }
    next: contentfulWhitepaper(slug: { eq: $nextPostSlug }) {
      slug
      title
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  DownloadButton,
  DownloadButtonWhite,
  GetInTouchCta,
  pageQuery,
  _frontmatter
};

const MDXLayout = ({
  children,
  data
}) => <Layout>
    <SEO title={data.contentfulWhitepaper.title} description={data.contentfulWhitepaper.description.childMarkdownRemark.excerpt} image={data.contentfulWhitepaper.heroImage.resize.src} article={true} />
    <div className="bg-white shadow-overlay">{children}</div>
  </Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <section>
  <div className="flex flex-wrap -mx-2 overflow-hidden">
    <div className="my-2 p-4 sm:p-6 lg:p-8 w-full overflow-hidden sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 flex items-center justify-center">
      <div>
        <h1 className="text-3xl font-bold">
          {props.data.contentfulWhitepaper.title}
        </h1>
        <div className="mt-4 text-gray-700 prose" dangerouslySetInnerHTML={{
              __html: props.data.contentfulWhitepaper.heroBody.childMarkdownRemark.html
            }} />
        <DownloadButton url={props.data.contentfulWhitepaper.whitepaper.file.url} mdxType="DownloadButton" />
      </div>
    </div>
    <div className="px-2 w-full overflow-hidden sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
      <GatsbyImage className="w-full min-h-[60vh] h-full object-cover" image={getImage(props.data.contentfulWhitepaper.heroImage)} alt={props.data.contentfulWhitepaper.title} mdxType="GatsbyImage" />
    </div>
  </div>
  <div className="bg-violet-light relative overflow-hidden">
    <div className="text-violet text-opacity-25 overflow-hidden">
      <IconBackground mdxType="IconBackground" />
    </div>
    <div className="mx-auto max-w-xl xl:max-w-2xl p-4 sm:p-6 lg:p-8 space-y-32 z-10 relative">
      <div className="prose py-12" dangerouslySetInnerHTML={{
            __html: props.data.contentfulWhitepaper.body.childMarkdownRemark.html
          }} />
    </div>
  </div>
  <div className="text-center font-medium py-12 bg-grape">
    <div className="text-2xl tracking-tight font-extrabold text-white sm:text-5xl md:text-2xl">
      Download the {props.data.contentfulWhitepaper.title} now
    </div>
    <div className="mt-10">
      <DownloadButtonWhite url={props.data.contentfulWhitepaper.whitepaper.file.url} mdxType="DownloadButtonWhite" />
    </div>
  </div>
    </section>





    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      